import { SET_USER } from './-mutation-types';
import { useUsersStore } from '~/stores/users';
import UserService from '~/services/api/UserService';
import type { User } from '~/models/User';

const Actions = {
    async fetchUser(userId: number) {
        const usersStore = useUsersStore();
        const userService = new UserService();
        const response = await userService.fetchUser(userId);

        usersStore[SET_USER](response);
    },
    setUser(user: User) {
        const usersStore = useUsersStore();
        usersStore[SET_USER](user);
    },
};

export default Actions;
