import { GET_USER } from './-getter-types';
import { useUsersStore } from '~/stores/users';
import type { User } from '~/models/User';

const Getters = {
    [GET_USER]: (): User | null => {
        const usersStore = useUsersStore();

        return usersStore.user;
    },
};

export default Getters;
